<template>
  <div>
    <b-row>
      <b-col>
        <h2>Invoice Summary</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Lookup by Invoice Number or Case Number"
          label-for="caseID"
        >
          <b-form-input
            id="caseID"
            v-model="InvoiceNumber"
            placeholder=""
            name="caseID"
          />
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 1.5rem;">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          :disabled="!InvoiceNumber"
          @click="lookup()"
        >
          Lookup
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span>{{ totalInvoices }} invoices</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'FileNumber'"
              class="text-nowrap"
            >
              <router-link :to="'/accounting/invoice-summary/' + props.row.InvoiceID">{{ props.row.FileNumber }}</router-link>
            </span>

            <!-- Column: Common -->
            <span v-else-if="props.column.field === 'CreatedAt'">
              {{ props.row.CreatedAt | dateFormat }}
            </span>

            <!-- Column: Common -->
            <span v-else-if="props.column.field === 'ExternalSystemSyncStatus'">
              {{ props.row.ExternalSystemSyncStatus + (props.row.VoidedAt ? (" / Voided by:  " + props.row.VoidedBy + " - " + dateFormater(props.row.VoidedAt)) : "" ) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">  Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                  {{ totalInvoices }} </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['100', '150', '200']"
                  class="mx-1"
                  @input="
                    (value) => {
                      props.perPageChanged({ currentPerPage: value })
                      onPageSizeChanged({ currentPerPage: value })
                    }
                  "
                />
                <span class="text-nowrap">per page</span>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :value="1"
                  :total-rows="totalInvoices"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                  @page-click="onPageChanged"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormSelect, BPagination, BRow} from "bootstrap-vue";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const Time = date.toLocaleTimeString("en-US", timeOptions);
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
  },
  data() {
    return {
      InvoiceNumber: "",
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      totalInvoices: 0,

      pageLength: 100,
      rows: [],
      columns: [
        {
          label: "Invoice Case Number",
          field: "FileNumber",
        },
        {
          label: "Invoice Number",
          field: "InvoiceDisplayNumber",
        },
        {
          label: "Created At",
          field: "CreatedAt",
        },
        {
          label: "Status",
          field: "ExternalSystemSyncStatus",
        },
      ]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    pageLength() {
      this.changePagination();
    },
    currentPage() {
      this.changePagination();
    }
  },
  created() {
    this.loadInvoiceList(1);
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    loadInvoiceList(pageNumber) {
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      apiService
          .get("invoice/list" +
              "?skip=" +
              startIndex +
              "&max=" +
              this.pageLength
          )
          .then(res => {
            this.rows = res.data.Invoices;
            this.totalInvoices = res.data.TotalCount;
            this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
          })
    },
    onPageChanged(bvEvent, page) {
      this.loadInvoiceList(page);
    },
    onPageSizeChanged(newPageSize) {
      this.loadInvoiceList(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },
    lookup() {
      apiService
          .get("invoice/number/" + this.InvoiceNumber)
          .then(res => {
            if (res.data && res.data.InvoiceID) {
              this.$router.push('/accounting/invoice-summary/' + res.data.InvoiceID)
            } else {
              this.showToast("warning", "top-center", 4000, "Nothing found");
            }
          })
    },
    changePagination() {
      var pageZeroIndex = this.currentPage - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      const endIndex = Number(this.pageLength) + this.displayStartIndex - 1;
      this.displayEndIndex = endIndex <= this.totalInvoices ? endIndex : this.totalInvoices;
    },
    dateFormater(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const Time = date.toLocaleTimeString("en-US", timeOptions);
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
  },
}
</script>

<style scoped>

</style>